<template>
<form @submit.prevent="">

<!-- {{note}} -->
    <div class="form-row" v-if="isMobile">
        <v-text-field class="mobile-app-input mobile-app-input-modal" label="عنوان یادداشت" id="note-name" v-model="note.title"></v-text-field>
    </div>
    <div class="form-row" v-if="isMobile">
        <v-select  v-model="note.category_id" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="categories" item-text="title" item-value="category_id" label="پوشه" outlined></v-select>
    </div>

    <div class="form-row" v-if="!isMobile">
        <div class="col-12">
            <input type="text" id="note-name" placeholder="عنوان یادداشت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="note.title">
        </div>
        <div class="col-12" style="margin-top: 10px;margin-bottom: 15px;">
            <div class="widget-select w-100 m-top folder-tags widget-select-lg">
                <v-select2 v-model="note.category_id" :clearable="false" label="title" :reduce="(option) => option.id" :options="categories">
                </v-select2>
            </div>
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-note"><i class="uil uil-save"></i> ذخیره تغییرات</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت برای مودال ویرایش  عنوان اسناد و یاداشت ها میباشد
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'NoteEdit',
    props: ['data'],
    components: {},
    data: function () {
        return {
            note: {
                title: '',
                category_id: null,
            },
            categories: [],
            isMobile:false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.note = this.data.note;
            this.categories = this.data.categories;

            if (!this.note.category_id) {
                this.note.category_id = this.categories[0].id;
            }
        },
        // این متد برای ذخیره سازی اطلاعات استفاده میشود
        // ارسال درخواست به پرنت جهت کال بک
        saveChanges() {
            if (this.note.title && this.note.category_id) {
                this.$parent.emitData('editNote', { note: this.note }, true);
            }
        },
    },
}
</script>
